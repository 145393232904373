
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { initReferrer } from '../helpers/referrer';
import '../styles/global-styles/fonts/index.css';
import { pushPageViewToDataLayer, pushToDataLayer, } from '../helpers/googleTagManager';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../styles/global-styles/index.scss';
import { useRegisterCustomElements } from '@/hooks/useRegisterCustomElements';
const ErrorBoundary = dynamic(() => import('@/components/ErrorBoundary'));
const MyApp = ({ Component, pageProps }: AppProps) => {
    const wasOriginalLocationStoredInDataLayer = useRef(false);
    const router = useRouter();
    useRegisterCustomElements();
    useEffect(() => {
        initReferrer();
        if (document.location && !wasOriginalLocationStoredInDataLayer.current) {
            pushToDataLayer('originalLocation', {
                originalLocation: document.location.protocol +
                    '//' +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search,
            });
            wasOriginalLocationStoredInDataLayer.current = true;
        }
        const handleRouteChange = () => {
            pushPageViewToDataLayer();
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);
    const isHomePage = router.pathname === '/' || router.pathname === '';
    // https://stackoverflow.com/a/14271049
    const HaxToFixOverflowXHiddenOnBodyForMobileDevices = {
        overflowX: isHomePage ? 'hidden' : 'visible',
    } as const;
    return (<ErrorBoundary>
      <div style={HaxToFixOverflowXHiddenOnBodyForMobileDevices}>
        <Component {...pageProps}/>
        <div id="modal-root"></div>
        <div id="youtube-player-root"></div>
      </div>
    </ErrorBoundary>);
};
const __Next_Translate__Page__19192d943ce__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19192d943ce__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  